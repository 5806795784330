import React, { useState } from 'react'
import { Box, Paper, TextField, DialogTitle, DialogContent, Stack, Grid } from "@mui/material/"
import validator from 'validator'
import LoadingButton from '@mui/lab/LoadingButton'
import BugSubmissionResult from './BugSubmissionResult.js'
import GetRoute from '../GetRoute'
import axios from 'axios'

export default function BugReport({ client }) {
    const routeIP = GetRoute(window.location.hostname)
    const protocol = window.location.protocol
    const bugFormInitState = {
        email: "",
        emailErrorValue: false,
        bugDescription: "",
        bugDescriptionErrorValue: false,
        submitting: false,
        bugFormErrorArray: [],
        bugFormErrorDialogToggle: false

    }

    const submissionInitState = {
        result: null,
        message: null, 
        submissionDialogToggle: false
    }

    const [bugForm, setBugForm] = useState(bugFormInitState)
    const [submissionResult, setSubmissionResult] = useState(submissionInitState)

    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value

        setBugForm(prev => ({...prev, [name]: value}))
    }

    const checkBugFormFields = () => {
        var hasError = false
        var alertArray = []

        if (!validator.isEmail(bugForm.email)) {
            alertArray.push("Enter your email")
            setBugForm(prev => ({...prev, emailErrorValue: true}))
            hasError = true
        }
        
        if (bugForm.bugDescription === "") {
            alertArray.push("Enter the bug descripton")
            setBugForm(prev => ({...prev, bugDescriptionErrorValue: true}))
            hasError = true
        }

        if (hasError) {
            setBugForm(prev => ({...prev, bugFormErrorArray: alertArray}))
            setBugForm(prev => ({...prev, bugFormErrorDialogToggle: true}))
        }

        return !hasError
    }

    const submitBugForm = async () => {
        try {
            await client.updateToken(5)
            const response = await axios.post(`${protocol}//${routeIP}:8000/utils/submitBugReport`, {
                email: bugForm.email,
                bugDescription: bugForm.bugDescription,
                token: client.token
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-store'  // Forces validation with the server, but can use cached data
                }
            })
            
            setSubmissionResult((prev) => ({...prev, result: response.data['result'], message: "Bug submission recieved. Our support team will be getting in touch with you."}))
            setBugForm(bugFormInitState)
        } catch (error) {
            alert(error)
        }
    } 

    return (
        <>
            <Box sx={{ backgroundColor: '#002233' }}>
                <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginInline: 'auto', maxWidth: '1920px', minHeight: 'calc(100vh - 63.59px)' }}>
                    <Grid item xs={1} sm={2} md={3} lg={3.5}/>
                    <Grid item xs={10} sm={8} md={6} lg={5}>
                        <Stack spacing={2}>
                            <Paper>
                                <DialogTitle sx={{ textAlign: 'left', backgroundColor: '#0e4780', color: 'white' }}>Bug Report Form</DialogTitle>
                                <DialogContent dividers sx={{ padding: 4 }}>
                                    <Stack spacing={2}>
                                        <TextField
                                            name='email'
                                            variant='outlined'
                                            label='Your Email'
                                            placeholder='Please enter your email here.'
                                            autoComplete="off"
                                            helperText="Your email will serve as a form of contact that support will use to reach out to you."
                                            value={bugForm.email}
                                            onChange={handleChange}
                                            error={ !(validator.isEmail(bugForm.email)) && bugForm.emailErrorValue === true}
                                        />
                                        <TextField
                                            multiline
                                            autoComplete="off"
                                            rows={6}
                                            value={bugForm.bugDescription}
                                            name='bugDescription'
                                            label='Bug Description'
                                            placeholder='Write a detailed description of what tool was being used, what the inputs were(if any) and what the resulting error was.'
                                            helperText="Please include what tool was being used, what the inputs were(if any) and what the resulting error was."
                                            error={ bugForm.bugDescriptionErrorValue === true && bugForm.bugDescription === '' }
                                            onChange={handleChange}
                                        />
                                        <LoadingButton
                                            loading={bugForm.submitting}
                                            id="submitButton"
                                            sx={{
                                                padding: 1.5,
                                                fontSize: 16,
                                                color: 'white',
                                                backgroundColor: '#0e4780',
                                                textTransform: 'none',
                                                '&:hover': { cursor: 'pointer', backgroundColor: '#003c6c' }, '& .MuiCircularProgress-root': { color: 'white' }
                                            }}
                                            onClick={ async () => {
                                                const button = document.querySelector("#submitButton")
                                                button.disabled = true
                                                setTimeout(() => button.disabled = false, 3000)
                                                setBugForm((prev) => ({...prev, submitting: true}))
                                                const isValid = checkBugFormFields()
                                                if (isValid) {
                                                    await new Promise(r => setTimeout(r, 1000))
                                                    await submitBugForm()
                                                    setSubmissionResult((prev) => ({...prev, submissionDialogToggle: true}))
                                                }
                                                setBugForm((prev) => ({...prev, submitting: false}))
                                            }}
                                        >
                                            Submit
                                        </LoadingButton>
                                    </Stack>
                                </DialogContent>
                            </Paper>
                        </Stack>
                    </Grid>
                    <Grid item xs={1} sm={2} md={3} lg={3.5}/>
                </Grid>
            </Box>
            { submissionResult.submissionDialogToggle ? (
                <BugSubmissionResult
                    submissionResult={submissionResult}
                    setSubmissionResult={setSubmissionResult}
                    submissionInitState={submissionInitState}
                />) :
                (<></>)
            }
        </>
    )
}