import { React } from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography, Dialog, DialogTitle, DialogContent, Stack, IconButton, Button, Grid } from "@mui/material/"
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

export default function SubmissionResult({ submissionResult, setSubmissionResult, closeVisitorRegDialog }) {
    const navigate = useNavigate()
    const closeSubmissionDialog = () => {
        if (submissionResult.result) {
            navigate('/VisitorSignInMenu')
        } else {
            setSubmissionResult((prev) => ({...prev, submissionDialogToggle: false }))
        }
    }

    return (
        <Dialog
            open={submissionResult.submissionDialogToggle}
            onClose={closeSubmissionDialog}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle sx={{ backgroundColor: '#0e4780', color: 'white' }}>
                Registration result
                <IconButton
                    size='small'
                    onClick={closeSubmissionDialog}
                    sx={{ float: 'right', color: 'white' }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers sx={{ display: 'flex', justifyContent: 'center'}}>
                <Stack spacing={2}>
                    { submissionResult.result === true ? 
                        <CheckIcon fontSize='large' color='success' sx={{ display: 'flex', alignSelf: 'center'}}/> : 
                        <ErrorOutlineIcon fontSize='large' color='error' sx={{ display: 'flex', alignSelf: 'center'}}/>
                    }
                    <Typography sx={{ fontSize: 20, textAlign: 'center' }}>
                        {submissionResult.message}
                    </Typography>
                    { submissionResult.result === true ? (
                        <Grid container sx={{ justifyContent: 'space-between', height: '100%' }}>
                            <Grid item xs={5.5}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    style={{ textTransform: 'none' }}
                                    id="submitButton"
                                    size='large'
                                    sx={{
                                        backgroundColor: '#0e4780',
                                        color: 'white', 
                                        "&:hover": { backgroundColor: '#0B3866', color: "white" }
                                    }}
                                    onClick={() => {
                                        navigate('/VisitorSignInMenu/SignIn')
                                    }}
                                >
                                    Yes
                                </Button>
                            </Grid>
                            <Grid item xs={5.5}>
                                <Button
                                    size='large'
                                    fullWidth
                                    variant="contained"
                                    style={{ textTransform: 'none' }}
                                    id="resetButton"
                                    sx={{ 
                                        backgroundColor: '#5d6c7c', 
                                        color: 'white', 
                                        "&:hover": { backgroundColor: '#4f5d6a', color: "white" }
                                    }}
                                    
                                    onClick={() => {
                                        navigate('/VisitorSignInMenu')
                                    }}
                                >
                                    No
                                </Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <></>
                    )
                    }
                </Stack>
            </DialogContent>
        </Dialog>
    )
}