import * as React from 'react'
import { AppBar, Box, Typography, IconButton, SwipeableDrawer, Divider, Grid } from '@mui/material/'
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv'
import AssessmentIcon from '@mui/icons-material/Assessment'
import LogoutIcon from '@mui/icons-material/Logout'
import Logo from '../Logo.png'
import MobileLogo from '../MobileLogo.png'

export default function NoRoleNavbar( {client} ) {
    const [machineMonitoringButton, setMachineMonitoringButton] = React.useState({ left: false })
    const [utilsButton, setUtilsButton] = React.useState({ left: false })

    const toggleMachineMonitoringDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return
        setMachineMonitoringButton({ ...machineMonitoringButton, [anchor]: open })
    }

    const toggleUtilsDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return
        setUtilsButton({ ...utilsButton, [anchor]: open})
    }

    const utilsList = (anchor) => (
        <Box
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 300 }}
        role="presentation"
        onClick={toggleUtilsDrawer(anchor, false)}
        onKeyDown={toggleUtilsDrawer(anchor, false)}
        >
        <Grid container>
            <Grid item xs={12}>
                <Typography align='center' sx={{ margin: 1 }} variant="h5">Utilities</Typography>
                <Divider/>
            </Grid>
        </Grid>
        </Box>
    )

    const machineMonitoringList = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 500 }}
            role="presentation"
            onClick={toggleMachineMonitoringDrawer(anchor, false)}
            onKeyDown={toggleMachineMonitoringDrawer(anchor, false)}
        >
            <Grid container>
                <Grid item xs={6}>
                    <Typography align='center' sx={{ margin: 1 }} variant="h5">Server Wall</Typography>
                    <Divider/>
                </Grid>
                <Grid item xs={6}>
                    <Typography align='center' sx={{ margin: 1 }} variant="h5">Departments</Typography>
                    <Divider/>
                    <Divider/>
                    <Typography align='center' sx={{ margin: 1 }} variant="h5">Buildings</Typography>
                    <Divider/>
                    <Divider/>
                    <Typography align='center' sx={{ margin: 1 }} variant="h5">DIY</Typography>
                    <Divider/>
                </Grid>
            </Grid>
        </Box>
    )

    return (
        <AppBar style={{ background: '#0e4780', position: 'sticky', padding: 5 }}>
            <Grid container sx={{ display: 'flex' }}>
                <Grid item>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', width: 'fit-content', '&:hover': { cursor: 'pointer', opacity: 0.8 } }}>
                        <img style={{ maxWidth: '80%', height: '70%' }} onClick={() => window.location.replace('/')} src={ Logo } alt="" />
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center', width: 'fit-content', '&:hover': { cursor: 'pointer', opacity: 0.8 } }}>
                        <img style={{ maxWidth: '70%', height: '60%', '&:hover': { cursor: 'pointer', opacity: 0.8 } }} onClick={() => window.location.replace('/')} src={ MobileLogo } alt="" />
                    </Box>
                </Grid>
                <Grid item sx={{ display: 'flex' }}>
                    <IconButton
                        size="large"
                        sx={{ display: {xs: 'none', md: 'flex'}, width: 'fit-content', '&:hover': { cursor: 'pointer', opacity: 0.8 } }}
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={toggleMachineMonitoringDrawer('left', true)}
                        color="inherit"
                    >
                        <ConnectedTvIcon/>
                        <Typography sx={{ paddingLeft: 1, display: { xs: 'none', md: 'flex' }, width: 'fit-content' }}>Machine Monitoring</Typography>
                    </IconButton>
                    <IconButton
                        size="medium"
                        sx={{ display: {xs: 'flex', md: 'none'}, width: 'fit-content', '&:hover': { cursor: 'pointer', opacity: 0.8 } }}
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={toggleMachineMonitoringDrawer('left', true)}
                        color="inherit"
                    >
                        <Typography sx={{ fontSize: 14, flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>Machine Monitoring</Typography>
                    </IconButton>
                    <SwipeableDrawer
                        anchor={'left'}
                        open={machineMonitoringButton['left']}
                        onClose={toggleMachineMonitoringDrawer('left', false)}
                        onOpen={toggleMachineMonitoringDrawer('left', true)}
                    >
                        {machineMonitoringList('left')}
                    </SwipeableDrawer>
                </Grid>
                <Grid item sx={{ display: 'flex' }}>
                    <IconButton
                        size="large"
                        sx={{ display: {xs: 'none', md: 'flex'}, width: 'fit-content', '&:hover': { cursor: 'pointer', opacity: 0.8 } }}
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={toggleUtilsDrawer('left', true)}
                        color="inherit"
                    >
                        <AssessmentIcon />
                        <Typography sx={{ paddingLeft: 1, display: { xs: 'none', md: 'flex' } }}>Utility</Typography>
                    </IconButton>
                    <IconButton
                        size="medium"
                        sx={{ display: {xs: 'flex', md: 'none'}, width: 'fit-content', '&:hover': { cursor: 'pointer', opacity: 0.8 } }}
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={toggleUtilsDrawer('left', true)}
                        color="inherit"
                    >
                        <Typography sx={{ fontSize: 14, flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>Utility</Typography>
                    </IconButton>
                    <SwipeableDrawer
                        anchor={'left'}
                        open={utilsButton['left']}
                        onClose={toggleUtilsDrawer('left', false)}
                        onOpen={toggleUtilsDrawer('left', true)}
                    >
                        {utilsList('left')}
                    </SwipeableDrawer>
                </Grid>
                <Grid item sx={{ display: 'flex', marginLeft: 'auto' }}>
                    <IconButton
                        size="large"
                        sx={{ display: {xs: 'none', md: 'flex'}, width: 'fit-content', paddingRight: 3, '&:hover': { cursor: 'pointer', opacity: 0.8 } }}
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => {client.logout({redirectUri: window.location.href})}}
                        color="inherit"
                    >
                        <LogoutIcon />
                        <Typography sx={{ paddingLeft: 1, display: { xs: 'none', md: 'flex' }, width: 'fit-content' }}>Sign out</Typography>
                    </IconButton>
                    <IconButton
                        size="medium"
                        sx={{ display: {xs: 'flex', md: 'none'}, '&:hover': { cursor: 'pointer', opacity: 0.8 } }}
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => {client.logout({redirectUri: window.location.href})}}
                        color="inherit"
                    >
                        <Typography sx={{ fontSize: 14, flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>Sign out</Typography>
                    </IconButton>
                </Grid>
            </Grid>
        </AppBar>
    )
}