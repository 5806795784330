import React from 'react'
import { Grid, Typography } from '@mui/material/'
import dayjs from 'dayjs'

const GenerateReport = ({ scopeObject, formValues, reportOptions, machineNames }) => {
    const startDate = formValues.startDate.format('MM-DD-YYYY')
    const endDate = formValues.endDate.format('MM-DD-YYYY')

    if (reportOptions === null) {
        return <React.Fragment></React.Fragment>
    }

    return (
        <div>
            <Grid container sx={{ justifyContent: 'space-between' }}>
                <Grid item md={6} lg={4}>
                    <Typography textAlign="left" variant="h6" style={{ fontFamily: 'monospace' }} sx={{ paddingLeft: 2 }}>
                        R&S Machining
                    </Typography>
                    <Typography textAlign="left" style={{ fontFamily: 'monospace' }} sx={{ paddingLeft: 2 }} variant="subtitle1">
                        Date Created: {dayjs(Date.now()).format('MM-DD-YYYY HH:mmA')}
                    </Typography>
                    <Typography textAlign="left" style={{ fontFamily: 'monospace' }} sx={{ paddingLeft: 2 }} variant="subtitle1">
                        Date Range: {startDate} - {endDate}
                    </Typography>
                </Grid>
                <Grid item/>
                <Grid item xs={12} style={{ fontFamily: 'monospace' }}>
                    { formValues.itemsToGet.length === 1 ? (
                        <Typography variant="h3" style={{ fontFamily: 'monospace', textDecoration: 'underline' }} textAlign="center">
                            {formValues.itemsToGet[0]}{ ' ' }{scopeObject[formValues.reportType]}
                        </Typography>
                        ) : (
                            <Typography variant="h3" style={{ fontFamily: 'monospace', textDecoration: 'underline' }} textAlign="center">
                                {scopeObject[formValues.reportType]}
                            </Typography>
                        )
                    }
                </Grid>
            </Grid>
            <Grid container sx={{ paddingTop: 1, display: 'flex', justifyContent: 'center' }}>
                {reportOptions}
                <Grid item md={12} textAlign="center" sx={{ margin: 4 }}>
                    <Typography variant="h6" style={{ fontFamily: 'monospace' }}>Machines included:</Typography>
                    <Typography variant="body2" style={{ fontFamily: 'monospace' }}>{machineNames}</Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default GenerateReport