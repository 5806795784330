import React, { useRef } from 'react'
import { IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material/'
import {generatePDF} from './utils'
import GenerateReport from './GenerateReport'
import CloseIcon from '@mui/icons-material/Close'
const MachineReportDialog = ({initialState, generateFormValues, setGenerateFormValues, report}) => {
    const reportRef = useRef()
    
    const closeReportDialog = () => {
        setGenerateFormValues(initialState)
    }
    
    return (
        <Dialog
                open={generateFormValues.reportDialogToggle}
                onClose={closeReportDialog}
                fullWidth
                maxWidth="lg"
                sx={{ color: '#0e4780' }}
            >
                <DialogTitle sx={{ backgroundColor: '#0e4780', color: 'white' }}>
                    R&S Machine Report
                    <IconButton
                        aria-label="close"
                        onClick={closeReportDialog}
                        sx={{ color: 'white', position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <div ref={reportRef}>
                        <GenerateReport
                            scopeObject={report.ScopeObject}
                            formValues={generateFormValues}
                            reportOptions={report.generatedReportOptions}
                            machineNames={report.machineNameString}
                        />
                    </div>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: '#5d6c7c' }}>
                    <Button
                        size='large'
                        id="pdfButton"
                        sx={{
                            backgroundColor: '#0e4780',
                            textTransform: 'none',
                            color: 'white',
                            padding: 1.5,
                            "&:hover": { backgroundColor: '#0B3866', color: "white" }
                        }}
                        onClick={() => {
                                const button = document.querySelector('#pdfButton')
                                button.disabled = true
                                setTimeout(() => button.disabled = false, 5000)
                                generatePDF(reportRef)
                            }
                        }
                    >
                        Download PDF
                    </Button>
                </DialogActions>
            </Dialog>
    )
}

export default MachineReportDialog