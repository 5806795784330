import { TableRow, Grid, Typography, Paper } from '@mui/material/' 
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import ProgramTbl from './Tables/ProgramTbl'
import ErrorsTbl from './Tables/ErrorsTbl'
import DailyRecordsTbl from './Tables/DailyRecordsTbl'
import DailyRecordHLATbl from './Tables/DailyRecordHLATbl'
import DailyRecordHLAGraph from './Graphs/DailyRecordHLAGraph'
import GroupDepartProgBarGraph from './Graphs/GroupDepartProgBarGraph'
import GroupDailyRecordHLAGraph from './Graphs/GroupDailyRecordHLA'
import GroupAvOpTimePieChart from './Graphs/GroupAvOpTimePieChart'
import GroupErrorTable from './Tables/GroupErrorTable'
import DailyRecordsBarGraph from './Graphs/DailyRecordsBarGraph'
import AverageOperatingTimePieChart from './Graphs/AverageOperatingTimePieChart'
import dayjs from 'dayjs'
import React from 'react'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

export const generatePDF = async (reportRef) => {
    const element = reportRef.current

    // Capture the report as a canvas using html2canvas
    const canvas = await html2canvas(element, { scale: 2 })
    const imgData = canvas.toDataURL('image/png')

    // Create a new instance of jsPDF
    const pdf = new jsPDF('portrait', 'pt', 'a4')

    const imgWidth = 595.28 // A4 width in pt
    const pageHeight = 841.89 // A4 height in pt
    const imgHeight = (canvas.height * imgWidth) / canvas.width
    let heightLeft = imgHeight
    let position = 0

    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
    heightLeft -= pageHeight

    // Add more pages if needed
    while (heightLeft > 0) {
        position = heightLeft - imgHeight
        pdf.addPage()
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
        heightLeft -= pageHeight
    }

    pdf.save('report.pdf')
}

const addAZero = (num) => {
    if (num < 10) {
        num = ('0' + num).slice(-2)
    }
    return num
}

export const secondsToHms = (d) => {
    d = Number(d)
    
    var h = Math.floor(d / 3600)
    h = addAZero(h)

    var m = Math.floor(d % 3600 / 60)
    m = addAZero(m)

    var s = Math.floor(d % 3600 % 60)
    s = addAZero(s)

    var hDisplay = h === 0 ? "" : h + ":"
    var mDisplay = m === 0 ? "" : m + ":"
    var sDisplay = s === 0 ? "" : s
    
    if (h === "00" && m === "00" && s === "00") {
        return 0
    }
    return hDisplay + mDisplay + sDisplay
    // return <React.Fragment><div style={{fontSize: 15}}>{hDisplay}</div><div style={{fontSize: 15}}>{mDisplay}</div><div style={{fontSize: 15}}>{sDisplay}</div></React.Fragment>
}

export const getMachineList = (parsedData) => {
    let machineNameString = ""

    for (const machine of parsedData) {
            machineNameString = machineNameString + machine.machineName + ", "
    }
    return machineNameString.slice(0, machineNameString.length - 2)
}

export const generateReportDataOptions = (parsedData, generateFormValues) => {
    var optionsToShow = []
    if (generateFormValues.groupOverview === true) {
        optionsToShow.push(
            <React.Fragment key="group">
                <Grid item sm={11} sx={{ marginLeft: 2 }}>
                    <Typography variant='h4' style={{ paddingLeft: 4, fontFamily: 'monospace', textDecoration: 'underline' }} gutterBottom sx={{ textAlign: 'left' }}>Overview</Typography>
                </Grid>
                <Grid item md={12} lg={6}>
                    <GroupAvOpTimePieChart
                        groupStatistics={parsedData.groupOverview.dailyStats}
                    />
                    <GroupDailyRecordHLAGraph
                        groupStatistics={parsedData.groupOverview.dailyStats}
                    />
                </Grid>
                <Grid item md={12} lg={4.5}>
                    <GroupErrorTable
                        groupErrors={parsedData.groupOverview.errorStats}
                    />
                </Grid>
                <Grid item>
                    <GroupDepartProgBarGraph
                        programStats={parsedData.groupOverview.programStats}
                    />
                </Grid>
            </React.Fragment>
        )
    }

    if (generateFormValues.AverageOperatingTimePieChart || generateFormValues.dailyRecordHLABarGraph || 
        generateFormValues.dailyRecordHLATable || generateFormValues.dailyRecordsBarGraph ||
        generateFormValues.dailyRecordsToggle || generateFormValues.errorTableToggle || generateFormValues.programTableToggle) {
            optionsToShow.push(
                <React.Fragment key="IndividualMachinesTitle">
                    <Grid item sm={12} sx={{ margin: 2 }}>
                        <Typography variant='h4' style={{ paddingLeft: 4, fontFamily: 'monospace', textDecoration: 'underline' }} gutterBottom sx={{ textAlign: 'left' }}>Individual Machine Data</Typography>
                    </Grid>
                </React.Fragment>
            )
        }

    optionsToShow.push(parsedData.machines.map( (machine) => {
        return (
            <React.Fragment key={machine.machineName}>
                { generateFormValues.dailyRecordHLABarGraph === true ? (
                    <Grid item sm={5.5}>
                        <DailyRecordHLAGraph
                            machineStatistics={machine.dailyRecordStats.statistics}
                            machineName={machine.machineName} 
                        />
                    </Grid>
                ):(<></>)}
                { generateFormValues.AverageOperatingTimePieChart === true ? (
                    <Grid item sm={5.5}>
                        <AverageOperatingTimePieChart
                            machineStatistics={machine.dailyRecordStats.statistics}
                            machineName={machine.machineName} 
                        />
                    </Grid>
                ):(<></>)}
                { generateFormValues.dailyRecordsBarGraph === true ? (
                    <Grid item>
                        <DailyRecordsBarGraph
                            machineStatistics={machine.dailyRecordStats.dailyRecords}
                            machineName={machine.machineName} 
                        />
                    </Grid>
                ):(<></>)}
                { generateFormValues.programTableToggle === true ? (
                    <Grid item>
                        <ProgramTbl
                            machineStatistics={machine.programStats}
                            machineName={machine.machineName}
                        />
                    </Grid>
                ):(<></>)}
                { generateFormValues.errorTableToggle === true ? (
                    <Grid item>
                        <ErrorsTbl
                            machineStatistics={machine.errorStats}
                            machineName={machine.machineName}
                        />
                    </Grid>
                ):(<></>)}
                { generateFormValues.dailyRecordsToggle === true ? (
                    <Grid item>
                        <DailyRecordsTbl
                            machineStatistics={machine.dailyRecordStats.dailyRecords}
                            machineName={machine.machineName}
                        />
                    </Grid>
                ):(<></>)}
                { generateFormValues.dailyRecordHLATable === true ? (
                    <Grid item>
                        <DailyRecordHLATbl
                            machineStatistics={machine.dailyRecordStats.statistics}
                            machineName={machine.machineName} 
                        />
                    </Grid>
                ):(<></>)}
            </React.Fragment>
        )
    }))

    return optionsToShow
} 

export const gatherReport = (ScopeObject, generateFormValues, generatedReportOptions , machineNameString) => {
    return (
        <Paper elevation={8} sx={{ margin: 4 }}>
            <Grid container sx={{ justifyContent: 'space-between' }}>
                <Grid item md={12} lg={3}>
                    <Typography textAlign="left" variant="h6" style={{ fontFamily: 'monospace' }} sx={{ paddingTop: 2, paddingLeft: 2 }}>R&S Machining</Typography>
                    <Typography textAlign="left" style={{ fontFamily: 'monospace' }} sx={{paddingLeft: 2}} variant="subtitle1">
                        Date: {dayjs(Date.now()).format('MM-DD-YYYY HH:mm')}
                    </Typography>
                </Grid>
                <Grid item md={6} lg={6}>
                </Grid>
                <Grid item xs={12} style={{ fontFamily: 'monospace' }}>
                    <Typography variant="h3" style={{ fontFamily: 'monospace' }} textAlign="center">{ScopeObject[generateFormValues.reportType]}</Typography>
                </Grid>
            </Grid>
            <Grid sx={{ paddingTop: 5, paddingBottom: 1, justifyContent: "center" }}>
                {generatedReportOptions}
                <Grid item md={12} lg={3} textAlign="center" sx={{ margin: 4 }}>
                    <Typography variant="h6" style={{ fontFamily: 'monospace' }}>Machines included:</Typography>
                    <Typography variant="h8" style={{ fontFamily: 'monospace' }}>{machineNameString}</Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#0e4780',
        color: theme.palette.common.white,
        fontSize: 18,
        fontFamily: 'monospace',
        padding: 16
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        fontFamily: 'monospace',
        padding: 8,
        paddingLeft: 16,
        paddingRight: 16
    },
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
    border: 0,
    },
}))