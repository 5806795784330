import { React, useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Typography, Box, Paper, TextField, Button, FormHelperText, Link } from "@mui/material/" 
import LoginIcon from '@mui/icons-material/Login'
import UnverifiedUserDialog from '../UnverifiedUser'
import SignInDialog from './SignInDialog'
import SignInConfirmationDialog from './SignInConfirmationDialog'
import AlreadyLoggedInDialog from './AlreadyLoggedInDialog'
import InactiveRedirect from '../InactiveRedirect'
import IdleUserDialog from '../IdleUser'
import GetRoute from '../../GetRoute'
import HandleAutoScroll from '../HandleAutoScroll'
import axios from 'axios'

export default function SignInVisitor ({ client }) {
    const navigate = useNavigate()
    const routeIP = GetRoute(window.location.hostname)
    const protocol = window.location.protocol
    InactiveRedirect(() => setLoginState((prev) => ({...prev, handleIdleUserToggle:  true})))

    const initLoginState = {
        email: "",
        visitorName: "",
        userVerified: null,
        isLoggedIn: null,
        unverifiedVisitorToggle: false,
        signInDialogToggle: null,
        confirmationDialogToggle: null,
        handleIdleUserToggle: false,
        message: "",
        fetchError: null,
        contact: null,
        contactErrorValue: false,
        availableContacts: [],
        loadingButtonToggle: false
    }
    const [loginState, setLoginState] = useState(initLoginState)
    const signInRef = useRef(null)
    console.log(signInRef.current)
    const checkUserExists = async () => {
        try {
            await client.updateToken(5)
            const response = await axios.post(`${protocol}//${routeIP}:8000/utils/checkUserExists`, {
                email: loginState.email,
                token: client.token
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-store'
                }
            })
            
            if (response.data['userVerified']) {
                setLoginState((prev) => ( {...prev, userVerified: response.data['userVerified'], visitorName: response.data['firstName'], message: 'Valid user'}))
            } else {
                setLoginState((prev) => ( {...prev, userVerified: response.data['userVerified'], visitorName: response.data['firstName'], message: 'Invalid email'}))
            }
            
        } catch (error) {
            alert(error)
            setLoginState((prev) => ({...prev, fetchError: error}))
        }
    }

    useEffect(() => {
        if (loginState.userVerified) {
            checkUserLogin()
        } else if (loginState.userVerified === false) {
            setLoginState((prev) => ({...prev, unverifiedVisitorToggle: true}))
        }// eslint-disable-next-line
    },[loginState.userVerified])

    const checkUserLogin = async () => {
        try {
            await client.updateToken(5)
            const response = await axios.post(`${protocol}//${routeIP}:8000/utils/checkUserLoginStatus`,{
                email: loginState.email,
                token: client.token
            },{
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-store'
                }
            })
            if (!response.data['isLoggedIn']) {
                setLoginState((prev) => ({...prev, isLoggedIn: response.data['isLoggedIn'], signInDialogToggle: true}))
            } else {
                setLoginState((prev) => ({...prev, isLoggedIn: response.data['isLoggedIn']}))
            }

        } catch (error) {
            alert(error)
            setLoginState((prev) => ({...prev, fetchError: error}))
        }
    }

    return (
        <Box sx={{ backgroundColor: '#002233' }}>
            <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginInline: 'auto', maxWidth: '1920px', minHeight: 'calc(100vh - 63.59px)' }}>
                <Grid item xs={0} sm={12} md={6}>
                    <Box className='apolloLogoBackground' ref={signInRef}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} sx={{ color: 'white', padding: '2%' }}>
                    <Grid container>
                        <Grid item xs={12} sx={{ marginBottom: 1 }}>
                            <Box>
                                <Paper elevation={4} sx={{ backgroundColor: '#0e4780' }}>
                                    <Typography gutterBottom variant="h4" sx={{ textAlign: 'center', color:'white', padding: .5, margin: 0 }}>Visitor Sign in</Typography>
                                </Paper>
                            </Box>
                        </Grid>
                        <Grid item xs={9.5} sx={{ paddingRight: 1 }}>
                            <Paper sx={{ backgroundColor: 'white', width:'100%' }}>
                                <TextField 
                                    fullWidth 
                                    id="fullWidth" 
                                    label="Email" 
                                    variant="filled" 
                                    name='email'
                                    autoComplete="off"
                                    
                                    value={loginState.email}
                                    onClick={() => {
                                        if (window.innerHeight < 900) {
                                            HandleAutoScroll(signInRef)
                                        }
                                    }}
                                    onChange={(e) => {setLoginState((prev) => ({...prev, email: e.target.value}))}}
                                    onBlur={() => {
                                        window.scrollTo({
                                            top: 0,
                                            behavior: 'smooth' // Smooth scrolling to the top
                                        })
                                    }}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={2.5} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                sx={{
                                    backgroundColor: '#0e4780',
                                    width: '100%',
                                    color: 'white',
                                    textTransform: 'none',
                                    fontSize:{ sm: 12, md: 14, lg: 16 },
                                    '&:hover': { cursor: 'pointer', backgroundColor: '#0B3866' }
                                }}
                                onClick={async () => {
                                    await checkUserExists()
                                }}
                            >
                                Sign in
                                <LoginIcon sx={{ paddingLeft: 1, color: 'white' }} />
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <FormHelperText 
                                sx={{ color: 'white', fontSize: 16 }}
                            >
                                Don't have an account?{' '}
                                <Link onClick={() => (navigate('/VisitorSignInMenu/CreateAccount'))} sx={{ '&:hover': { cursor: 'pointer', color: 'white' } }}>Create account</Link>
                            </FormHelperText>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            { loginState.unverifiedVisitorToggle ? 
                <UnverifiedUserDialog 
                    client={client} 
                    loginState={loginState}
                    setLoginState={setLoginState}
                    initLoginState={initLoginState}
                /> : <></>
            }
            { loginState.signInDialogToggle ? 
                <SignInDialog 
                    client={client}  
                    loginState={loginState}
                    setLoginState={setLoginState}
                    initLoginState={initLoginState}
                /> : <></>
            }
            { loginState.confirmationDialogToggle ? 
                <SignInConfirmationDialog 
                    loginState={loginState} 
                    setLoginState={setLoginState} 
                    initLoginState={initLoginState} 
                /> : <></>
            }
            { loginState.isLoggedIn ?
                <AlreadyLoggedInDialog
                    loginState={loginState} 
                    setLoginState={setLoginState} 
                    initLoginState={initLoginState} 
                /> : <> </>
            }
            { loginState.handleIdleUserToggle ?
                <IdleUserDialog
                    state={loginState} 
                    setState={setLoginState} 
                /> : <> </>
            }
        </Box>
    )
}