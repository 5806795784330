import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Typography, Card, CardContent, CardMedia, Button } from '@mui/material/'
import useOnScreen from '../../../hooks/useOnScreen'

const Feature = ({ feature }) => {
    const navigate = useNavigate()
    const ref = useRef()
    const isVisible = useOnScreen(ref, '-100px')

    const fadeStyles = {
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? 'none' : 'translateY(50px)',
        transition: 'opacity 1s ease-out, transform 1s ease-out',
    }

    if (feature.index % 2 === 0) {
        return (
            <>
                <Grid item md={1}/>
                <Grid item xs={12} md={5}
                    ref={ref} style={fadeStyles}
                    sx={{
                        order: {
                            xs: feature.discriptionSX,
                            sm: feature.discriptionSM,
                            md: feature.discriptionMD
                        },
                        minHeight: { lg: '500px' },
                        alignContent: 'center'
                    }}
                >
                    <Typography color="#1d9fdd" 
                        sx={{
                            typography: {
                                xs: 'h5',
                                sm: 'h4',
                                md: 'h3',
                                lg: 'h2'
                            }
                        }}
                    >
                        {feature.title}
                    </Typography>
                    <Typography color="#203b72">
                        {feature.description}
                    </Typography>
                    { feature.button ? (
                        <Button variant="contained" size="large"
                            sx={{
                                textTransform: 'none',
                                marginTop: 2,
                                backgroundColor: '#0e4780',
                                '&:hover': { bgcolor: '#0B3866' }
                            }}
                            onClick={() => {
                                navigate(feature.buttonRedirect)
                            }}
                        >
                            {feature.buttonTitle}
                        </Button>
                    ) : (<></>)}
                </Grid>
                <Grid item xs={12} md={5}
                    ref={ref} style={fadeStyles}
                    sx={{
                        backgroundColor: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        order: {
                            xs: feature.imageSX,
                            sm: feature.imageSM,
                            md: feature.imageSX
                        },
                        paddingBottom: {
                            xs: 6,
                            sm: 6,
                            md: 0
                        }
                    }}
                >
                    <Card 
                        variant="plain"
                        sx={{
                            backgroundColor: 'inherit',
                            transition: "transform 0.15s ease-in-out",
                            "&:hover": { transform: "scale(1.1, 1.1)"}
                        }}
                    >
                        <CardContent>
                        <CardMedia
                            component="img"
                            image={feature.image}
                            alt={feature.title}
                        />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={1}/>
            </>
        )
    }
    
    return (
        <>
            <Grid item md={1}/>
            <Grid item xs={12} md={5}
                ref={ref} style={fadeStyles}
                sx={{
                    backgroundColor: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    order: {
                        xs: feature.discriptionSX,
                        sm: feature.discriptionSM,
                        md: feature.discriptionMD
                    },
                    minHeight: { lg: '500px' },
                    paddingBottom: {
                        xs: 6,
                        sm: 6,
                        md: 0
                    }
                }}
            >
                <Card 
                    variant="plain"
                    sx={{
                        backgroundColor: 'inherit',
                        alignContent: 'center',
                        transition: "transform 0.15s ease-in-out",
                        "&:hover": { transform: "scale(1.1, 1.1)" } }}
                >
                    <CardContent>
                    <CardMedia
                        component="img"
                        image={feature.image}
                        alt={feature.title}
                    />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={5}
                ref={ref} style={fadeStyles}
                sx={{
                    order: {
                        xs: feature.imageSX,
                        sm: feature.imageSM,
                        md: feature.imageSX
                    }
                }}
            >
                <Typography color="#1d9fdd"
                    sx={{
                        typography: {
                            xs: 'h5',
                            sm: 'h4',
                            md: 'h3',
                            lg: 'h2'
                        }
                    }}
                >
                    {feature.title}
                </Typography>
                <Typography color="#203b72">
                    {feature.description}
                </Typography>
                { feature.button ? (
                    <Button
                        variant="contained"
                        size="large"
                        sx={{
                            textTransform: 'none',
                            marginTop: 2,
                            backgroundColor: '#0e4780',
                            '&:hover': { bgcolor: '#0B3866' }
                        }}
                        onClick={() => {
                            navigate(feature.buttonRedirect)
                        }}
                    >
                        {feature.buttonTitle}
                    </Button>
                ) : (<></>)}
            </Grid>
            <Grid item md={1}/>
        </>
    )
}

export default Feature