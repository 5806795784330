import React, { useState } from "react"
import { Card, CardContent, Typography, Grid, CardMedia } from '@mui/material/'
import { useSpring, animated } from "react-spring"
import { getScreenSizeCategory, parseProgramName } from './serverWallUtils'
import { handleExecutionName, secondsToHms, toHrs, handlePathPosition, determineCardColor } from '../machineDashboardUtils'

//This react component returns the individual machine card based on the information collected from MachineDataRoute.js
const MachineCard = ({
    name,
    execution,
    program,
    pathFeedrateOverride,
    pathFeedrate,
    operatingTime,
    spindleRunTime,
    spindleRunTimePerMonth,
    pathPosition,
    spindleStartTimePerProgram
    }) => {

    //Functions declared below that format the data in a more attractive way
    const splitName = name.split("-")
    const imageFileName = splitName[1] + '.jpg'
    const styleUpdateArray = getScreenSizeCategory(window.innerWidth)

    let imageUrl
    try {
        imageUrl = require(`./images/${imageFileName}`)
    } catch (error) {
        imageUrl = require(`./images/matthew.jpg`)
    }

    const modifiedExecution = handleExecutionName(execution)
    const formattedProgramName = parseProgramName(program)
    //Divide incoming feedrate by 25.4 to convert it to inches then times by 60 to get per minute
    const floatPathFeedrate = parseFloat((pathFeedrate / 25.4) * 60)
    const path = handlePathPosition(pathPosition)
    const cardColor = determineCardColor(modifiedExecution)
    const parsedOT = parseInt(operatingTime)
    const parsedST = parseInt(spindleRunTime) - parseInt(spindleStartTimePerProgram)

    //These are useStates and the following consts are used for the animation on the cards
	const [animatedX, setAnimatedX] = useState(path[0])
	const [animatedY, setAnimatedY] = useState(path[1])
	const [animatedZ, setAnimatedZ] = useState(path[2])
    const [animatedPPF, setAnimatedPPF] = useState(floatPathFeedrate)
    const [animatedOT, setAnimatedOT] = useState(parsedOT)
    const [animatedST, setAnimatedST] = useState(parsedST)
    

    const animatedPropsST = useSpring({
        to: { animatedST: parsedST || 0 },
        config: { mass: .5, tension: .1, friction: .5 },
        onFrame: ({ animatedST }) => {
            setAnimatedOT(animatedST)
        },
    })
    
    const animatedPropsOT = useSpring({
        to: { animatedOT: parsedOT || 0 },
        config: { mass: .5, tension: .1, friction: .5 },
        onFrame: ({ animatedOT }) => {
            setAnimatedOT(animatedOT)
        },
    })

    const animatedPropsPPF = useSpring({
        to: { animatedPPF: floatPathFeedrate || 0},
        config: {mass: 1, tension: 500, friction: 50},
        onFrame: ({ animatedPPF }) => {
            setAnimatedPPF(animatedPPF.toFixed(2))
        }
    })
	
    const animatedPropsX = useSpring({
		to: { animatedX: path[0] || 0 },
		config: {mass: 1, tension: 200, friction: 75 },
		onFrame: ({ animatedX }) => {
            setAnimatedX(Math.trunc(animatedX))
		},
	})
	
	const animatedPropsY = useSpring({
		to: { animatedY: path[1] || 0 },
		config: {mass: 1, tension: 200, friction: 75 },
		onFrame: ({ animatedY }) => {
		setAnimatedY(Math.trunc(animatedY))
		},
	})
	
	const animatedPropsZ = useSpring({
		to: { animatedZ: path[2] || 0 },
		config: {mass: 1, tension: 200, friction: 75 },
		onFrame: ({ animatedZ }) => {
		setAnimatedZ(Math.trunc(animatedZ))
		},
	})
	
    //Return the card
    return (
        <Card style={{ backgroundColor: cardColor }} sx={{ minWidth: '250', boxShadow: 10, margin: 1, border: 1 }} variant="outlined">
            <Typography style={ styleUpdateArray[0] } align='center'>
                {name}
            </Typography>
            <CardMedia 
                component="img"
                // maxHeight="200px"
                style={styleUpdateArray[7]}
                sx={{borderTop: 1, borderBottom: 1}}
                src= {imageUrl}
                alt="machine image not found"
            />
            <CardContent style={{ padding: "16px" }} sx={{ borderColor: '#000000' }}>
                <Grid container sx={{ mb: 2 }}>
                    <Grid item xs={5} sm={5} md={5} lg={5} xl={4.5} sx={{ border: 1, alignSelf: 'baseline' }}>
                        <Typography style={ styleUpdateArray[5] } variant="body1" align="center">
                            {modifiedExecution}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={2} ></Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={5.5} sx={{ border: 1, background: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography style={ styleUpdateArray[6] } variant="body1" align="center">
                            {formattedProgramName}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container sx={{ mb: 1 }}>
                    <Grid item xs={6}>
                        <animated.div align="left" style={ styleUpdateArray[1] }>
							{animatedPropsPPF.animatedPPF.to((floatPathFeedrate) => `Feedrate ${floatPathFeedrate.toFixed(2)}`)}
						</animated.div>
                    </Grid>
                    <Grid item xs={2} style={{ background: '#0e4780' }} sx= {{ border: 1 }}>
                        <Typography variant="body1" align="center" style={styleUpdateArray[8]}>
                            X
                        </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ background: '#0e4780' }} sx= {{ borderTop: 1, borderBottom: 1 }}>
                        <Typography variant="body1" align="center" style={styleUpdateArray[8]}>
                            Y
                        </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ background: '#0e4780' }} sx= {{ border: 1 }}>
                        <Typography variant="body1" align="center" style={styleUpdateArray[8]}>
                            Z
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={ styleUpdateArray[1] } variant="body1" align="left">
                            Override {pathFeedrateOverride}%
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sx= {{ border: 1, borderTop: 0 }}>
                        <animated.div style={ styleUpdateArray[2] } variant="body1" align="center">
							{animatedPropsX.animatedX.to((x) => x.toFixed(1))}
						</animated.div>
                    </Grid>
                    <Grid item xs={2} sx= {{ borderBottom: 1 }}>
						<animated.div style={ styleUpdateArray[2] } variant="body1" align="center">
							{animatedPropsY.animatedY.to((y) => y.toFixed(1))}
						</animated.div>
                    </Grid>
                    <Grid item xs={2} sx= {{ border: 1, borderTop: 0 }}>
						<animated.div style={ styleUpdateArray[2] } variant="body1" align="center">
							{animatedPropsZ.animatedZ.to((z) => z.toFixed(1))}
						</animated.div>
                    </Grid>                   
                </Grid>
                <Grid container sx={{ mt: 2 }}>
                    <Grid item xs={12}>
                        <animated.div align="left" style={ styleUpdateArray[3] }>
							{animatedPropsOT.animatedOT.to((parsedOT) => `Machine OT ${secondsToHms(parsedOT)}`)}
						</animated.div>
                    </Grid>
                    <Grid item xs={9} md={9} lg={10}>
                        <animated.div align="left" style={ styleUpdateArray[3] }>
							{animatedPropsST.animatedST.to((parsedST) => `Program ST ${secondsToHms(parsedST)}`)}
						</animated.div>
                    </Grid>
                    <Grid item xs={3} md={3} lg={2}>
                        <Typography style={ styleUpdateArray[4] } sx={{ border: 1 }} variant="body1" align="center">
                            {toHrs(spindleRunTimePerMonth)}/440
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default MachineCard