import React, { useState } from "react"
import { Card, CardContent, Typography, Grid } from '@mui/material/'
import { useSpring, animated } from "react-spring"
import { handleExecutionName, secondsToHms, toHrs, handlePathPosition, determineCardColor, pathPositionStyle, timeStyle, feedStyle } from '../machineDashboardUtils'
import AssessmentIcon from '@mui/icons-material/Assessment'
import MachineReportPopover from './MachineReportPopover'


//This react component returns the individual machine card based on the information collected from MachineDataRoute.js
const MachineCard = ({
    name,
    execution,
    program,
    pathFeedrateOverride,
    pathFeedrate,
    operatingTime,
    spindleRunTime,
    spindleRunTimePerMonth,
    pathPosition,
    spindleStartTimePerProgram,
    client
    }) => {

    //Functions declared below that format the data in a more attractive way
    const modifiedExecution = handleExecutionName(execution)
    const truncatedProgram = program ? program.slice(0, -4) : ''
    //Divide incoming feedrate by 25.4 to convert it to inches then times by 60 to get per second
    const floatPathFeedrate = parseFloat((pathFeedrate / 25.4) * 60)
    const path = handlePathPosition(pathPosition)
    const cardColor = determineCardColor(modifiedExecution)
    const parsedOT = parseInt(operatingTime)
    const parsedST = parseInt(spindleRunTime) - parseInt(spindleStartTimePerProgram)

    //These are useStates and the following consts are used for the animation on the cards
	const [animatedX, setAnimatedX] = useState(path[0])
	const [animatedY, setAnimatedY] = useState(path[1])
	const [animatedZ, setAnimatedZ] = useState(path[2])
    const [animatedPPF, setAnimatedPPF] = useState(floatPathFeedrate)
    const [animatedOT, setAnimatedOT] = useState(parsedOT)
    const [animatedST, setAnimatedST] = useState(parsedST)
    

    const animatedPropsST = useSpring({
        to: { animatedST: parsedST || 0 },
        config: { mass: .5, tension: .1, friction: .5 },
        onFrame: ({ animatedST }) => {
            setAnimatedOT(animatedST)
        },
    })
    
    const animatedPropsOT = useSpring({
        to: { animatedOT: parsedOT || 0 },
        config: { mass: .5, tension: .1, friction: .5 },
        onFrame: ({ animatedOT }) => {
            setAnimatedOT(animatedOT)
        },
    })

    const animatedPropsPPF = useSpring({
        to: { animatedPPF: floatPathFeedrate || 0},
        config: {mass: 1, tension: 500, friction: 50},
        onFrame: ({ animatedPPF }) => {
            setAnimatedPPF(animatedPPF.toFixed(2))
        }
    })
	
    const animatedPropsX = useSpring({
		to: { animatedX: path[0] || 0 },
		config: {mass: 1, tension: 200, friction: 75 },
		onFrame: ({ animatedX }) => {
            setAnimatedX(Math.trunc(animatedX))
		},
	})
	
	const animatedPropsY = useSpring({
		to: { animatedY: path[1] || 0 },
		config: {mass: 1, tension: 200, friction: 75 },
		onFrame: ({ animatedY }) => {
		setAnimatedY(Math.trunc(animatedY))
		},
	})
	
	const animatedPropsZ = useSpring({
		to: { animatedZ: path[2] || 0 },
		config: {mass: 1, tension: 200, friction: 75 },
		onFrame: ({ animatedZ }) => {
		setAnimatedZ(Math.trunc(animatedZ))
		},
	})

    const handleClick = (name) => {
        console.log(name,  "clicked")
    }
	
    //Return the card
    return (
        <Card style={{ backgroundColor: cardColor }} sx={{ minWidth: '250', boxShadow: 5, margin: 1 }} variant="outlined">
            <CardContent style={{ padding: "4px 16px 12px 16px" }}>
            <Grid container sx={{ mb: 2 }}>
                <Grid item xs={11}>
                    <Typography sx={{ paddingBottom: 1, fontSize: {sm: 18, md: 26, lg: 26} }} align='center'>
                        {name}
                    </Typography>
                </Grid>
                <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
                    <MachineReportPopover name={name} client={client}/>
                </Grid>
                    <Grid item xs={5} sm={5} md={5} lg={5} sx={{ border: 1, background: "white" }}>
                        <Typography sx={{ fontSize: 15 }} align="center">
                            { modifiedExecution.length === 0 ? " " : modifiedExecution }
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} sx={{ border: 1, background: "white" }}>
                        <Typography style={{ backgroundColor: "#FFFFFF" }} sx={{ fontSize: 15 }} variant="body1" align="center">
                            {truncatedProgram}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container sx={{ mb: 1 }}>
                    <Grid item xs={6}>
                        <animated.div align="left" style={ feedStyle }>
							{animatedPropsPPF.animatedPPF.to((floatPathFeedrate) => `Feedrate ${floatPathFeedrate.toFixed(2)}`)}
						</animated.div>
                    </Grid>
                    <Grid item xs={2} style={{ background: '#0e4780' }} sx={{ border: 1 }}>
                        <Typography align="center" sx={{ color: "white", fontSize: 19, fontFamily: 'Segoe UI Symbol' }}>
                            X
                        </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ background: '#0e4780' }} sx={{ borderTop: 1, borderBottom: 1 }}>
                        <Typography align="center" sx={{ color: "white", fontSize: 19, fontFamily: 'Segoe UI Symbol' }}>
                            Y
                        </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ background: '#0e4780' }} sx={{ border: 1 }}>
                        <Typography align="center" sx={{ color: "white", fontSize: 19, fontFamily: 'Segoe UI Symbol' }}>
                            Z
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography style={{ fontSize: 20,  fontFamily: 'Segoe UI Symbol' }}>
                            Override {pathFeedrateOverride}%
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sx= {{ border: 1, borderTop: 0 }}>
                        <animated.div style={pathPositionStyle} align="center">
							{animatedPropsX.animatedX.to((x) => x.toFixed(1))}
						</animated.div>
                    </Grid>
                    <Grid item xs={2} sx= {{ borderBottom: 1 }}>
						<animated.div style={ pathPositionStyle } align="center">
							{animatedPropsY.animatedY.to((y) => y.toFixed(1))}
						</animated.div>
                    </Grid>
                    <Grid item xs={2} sx= {{ border: 1, borderTop: 0 }}>
						<animated.div style={ pathPositionStyle } align="center">
							{animatedPropsZ.animatedZ.to((z) => z.toFixed(1))}
						</animated.div>
                    </Grid>                   
                </Grid>
                <Grid container sx={{ mt: 2 }}>
                    <Grid item xs={12}>
                        <animated.div align="left" style={ timeStyle }>
							{animatedPropsOT.animatedOT.to((parsedOT) => `Machine OT ${secondsToHms(parsedOT)}`)}
						</animated.div>
                    </Grid>
                    <Grid item xs={9.5}>
                        <animated.div align="left" style={ timeStyle }>
							{animatedPropsST.animatedST.to((parsedST) => `Program ST ${secondsToHms(parsedST)}`)}
						</animated.div>
                    </Grid>
                    <Grid item xs={2.5} sx={{ border: 1, background: "white" }}>
                        <Typography sx={{ fontSize: 16 }} variant="body1" align="center">
                            {toHrs(spindleRunTimePerMonth)}/440
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default MachineCard