import * as React from 'react'
import { useState } from 'react'
import { Box, Grid, Paper, Typography, FormControl, FormControlLabel, Button, TextField } from '@mui/material/'
import LinearProgress from '@mui/material/LinearProgress'
import WcLegend from './components/WcLegend'
import StatusTable from './components/StatusTable'
import Android12Switch from './components/Android12Switch'
import GetRoute from '../GetRoute'
import axios from 'axios'

export default function POStatusForm({ client }) {
    const routeIP = GetRoute(window.location.hostname)
    const protocol = window.location.protocol

    const initialState = {
        requestedPO: "",
        response: null,
        requestedPOErrorValue: false,
        workCenterListToggle: false,
        commentsToggle: false,
        linearProgressToggle: false
    }

    const [POStatusState, setPOStatusState] = useState(initialState)

    const fetchReport = async () => {
        setPOStatusState(prev => ({...prev, linearProgressToggle: true}))
        try {
            await client.updateToken(5)
            const response = await axios.post(`${protocol}//${routeIP}:8000/utils/fetchPO`, {
                requestedPO: POStatusState.requestedPO,
                workCenterListToggle: POStatusState.workCenterListToggle,
                commentsToggle: POStatusState.commentsToggle,
                token: client.token
            }, {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-store'
            })

            setPOStatusState(prev => ({...prev, response: response.data}))
            setPOStatusState(prev => ({...prev, linearProgressToggle: false}))
        } catch (error) {
            alert(error)
            setPOStatusState(prev => ({...prev, linearProgressToggle: false}))
        }
    }
    
    const handleSubmit = async () => {
        if (POStatusState.requestedPOErrorValue === false) {
            await fetchReport()
        } else {
            alert("Invalid purchase order")
        }
        
    }

    const handleChange = async (event) => {
        const name = event.target.name
        const value = event.target.value
        const charRegex = /[a-zA-Z]/

        if (charRegex.test(value) && POStatusState.requestedPOErrorValue === false) {
            await setPOStatusState(prev => ({...prev, requestedPOErrorValue: true}))
        } else if (charRegex.test(value) === false && POStatusState.requestedPOErrorValue === true) {
            await setPOStatusState(prev => ({...prev, requestedPOErrorValue: false}))
        }
        await setPOStatusState(prev => ({...prev, [name]: value}))
    }
    const handleReset = () => {
        setPOStatusState(initialState)
    }

    return (
        <Grid container sx={{ height: '100%', display: 'flex', gridTemplateRows: 'auto 1fr' }}>
            <Grid item sm={12} sx={{ height: '30%', minHeight: '171px' }}>
                <Grid container sx={{ height: '100%' }}>
                    <Grid item sm={10} sx={{ height: '100%' }}>
                        <Paper elevation={1} sx={{ height: '100%' }}>
                            { POStatusState.linearProgressToggle === true ? (
                                <Box sx={{ width: '100%' }}>
                                    <LinearProgress />
                                </Box>
                                ) : (<Box sx={{ width: '100%', minHeight: '4px', backgroundColor: 'white' }}></Box>
                            )}
                            <Grid container sx={{ display: 'flex', justifyContent:'space-evenly', height: '100%' }}>
                                <Grid item sm={5}>
                                    <Box sx={{ margin: 1 }}>
                                        <Paper elevation={4} sx={{ backgroundColor: '#0e4780' }}>
                                            <Typography gutterBottom variant="h6" sx={{ textAlign: 'center', color:'white' }}>Input PO Number</Typography>
                                        </Paper>
                                        <Grid container>
                                            <Grid item sm={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <FormControl sx={{ width: '80%', marginTop:.5}}>
                                                    <TextField
                                                        required
                                                        autoComplete='off'
                                                        id="selectedPO"
                                                        label="Purchase Order"
                                                        name="requestedPO"
                                                        error={POStatusState.requestedPOErrorValue}
                                                        value={POStatusState.requestedPO}
                                                        onChange={handleChange}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item sm={5}>
                                    <Box sx={{ margin: 1 }}>
                                        <Paper elevation={4} sx={{ backgroundColor: '#0e4780' }}>
                                            <Typography gutterBottom variant="h6" sx={{ textAlign: 'center', color:'white' }}>Include</Typography>
                                        </Paper>
                                        <Grid container sx={{ marginTop: 2, display: 'flex', justifyContent:'space-evenly' }}>
                                            <Grid item sm={5} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <FormControlLabel
                                                    sx={{ margin: 0 }}
                                                    control={
                                                        <Android12Switch
                                                            checked={POStatusState.workCenterListToggle}
                                                            onChange={(e) => {
                                                                return setPOStatusState(prev => ({...prev, workCenterListToggle: !POStatusState.workCenterListToggle}))
                                                            }}
                                                        />
                                                    }
                                                    label="Work Centers (LOB)"
                                                />
                                            </Grid>
                                            <Grid item sm={5} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <FormControlLabel
                                                    sx={{ margin: 0 }}
                                                    control={
                                                        <Android12Switch
                                                            checked={POStatusState.commentsToggle}
                                                            onChange={(e) => {
                                                                return setPOStatusState(prev => ({...prev, commentsToggle: !POStatusState.commentsToggle}))
                                                            }}
                                                        />
                                                    }
                                                    label="Comments"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item sm={12}>
                                    <Grid container sx={{ justifyContent: 'flex-end', height: '100%' }}>
                                        <Grid item sx={{ paddingBottom: 1.5, alignContent: 'end' }}>
                                            <Button
                                                size='large'
                                                variant="contained"
                                                style={{ textTransform: 'none' }}
                                                id="submitButton"
                                                sx={{
                                                    backgroundColor: '#0e4780',
                                                    width: 120,
                                                    color: 'white',
                                                    "&:hover": { backgroundColor: '#0B3866', color: "white" }
                                                }}
                                                onClick={() => {
                                                    handleSubmit()
                                                    const button = document.querySelector('#submitButton')
                                                    button.disabled = true
                                                    setTimeout(() => button.disabled = false, 1000)
                                                }}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                        <Grid item sx={{ paddingBottom: .5, margin: 1, alignContent: 'end' }}>
                                            <Button
                                                size='large'
                                                variant="contained"
                                                style={{ textTransform: 'none' }}
                                                id="resetButton"
                                                sx={{
                                                    backgroundColor: '#5d6c7c',
                                                    width: 120,
                                                    color: 'white',
                                                    "&:hover": { bgcolor: '#4f5d6a', color: "white" }
                                                }}
                                                onClick={() => {
                                                    handleReset()
                                                    const button = document.querySelector('#resetButton')
                                                    button.disabled = true
                                                    setTimeout(() => button.disabled = false, 1000)
                                                }}
                                            >
                                                Reset
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item sm={2} sx={{ height: '100%' }}>
                        <WcLegend/>
                    </Grid>
                </Grid>
            </Grid>
            { POStatusState.response != null ? (
                <Grid item sm={12} sx={{ marginTop: 1, backgroundColor: 'lightgrey', height: '69%' }}>
                    <StatusTable State={POStatusState} />
                </Grid>
            ):(<></>)}
        </Grid>
    )
}