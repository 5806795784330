import { useState, useEffect, useRef } from 'react'
import Keycloak from 'keycloak-js'

// Determine the URL based on window.location
const keycloakConfig = (() => {
    let url = ''

    // Use correct URL based on the environment
    if (window.location.hostname === 'localhost' || window.location.hostname === '192.168.110.164') {
        // Local environment
        url = 'http://192.168.110.164:8080'
    } else {
        // Production environment
        url = 'https://apollologin.randsmachine.com'
    }

    return {
        url: url,
        realm: 'Apolloapp',
        clientId: 'Apollo', // Ensure clientId is set correctly
    }
})()

// Initialize Keycloak with the configuration object
const client = new Keycloak(keycloakConfig)

const useAuth = () => {
    const isRun = useRef(false)
    const [authenticated, setAuthenticated] = useState(false)

    useEffect(() => {
        if (!isRun.current) {
            isRun.current = true

            // Initialize Keycloak and handle authentication
            client.init({
                onLoad: 'login-required',
                checkLoginIframe: false,
                pkceMethod: 'S256',
            }).then((auth) => {
                if (!auth) {
                    window.location.reload()
                } else {
                    setAuthenticated(true)
                }
            }).catch((error) => {
                console.error("Keycloak initialization error:", error)
            })
        }
    }, [])

    return [client, authenticated]
}

export default useAuth
