import * as React from 'react'
import { useState } from 'react'
import { Box, Grid, Paper, Typography, FormControl, FormControlLabel, Button, TextField } from '@mui/material/'
import LinearProgress from '@mui/material/LinearProgress'
import WcLegend from './components/WcLegend'
import StatusTable from './components/StatusTable'
import Android12Switch from './components/Android12Switch'
import GetRoute from '../GetRoute'
import axios from 'axios'

export default function JobStatusForm({ client }) {
    const routeIP = GetRoute(window.location.hostname)
    const protocol = window.location.protocol

    const initialState = {
        requestedJob: "",
        response: null,
        workCenterListToggle: false,
        commentsToggle: false,
        componentJobsToggle: false,
        linearProgressToggle: false
    }

    const [JobStatusState, setJobStatusState] = useState(initialState)

    const fetchReport = async () => {
        setJobStatusState(prev => ({...prev, linearProgressToggle: true}))
        try {
            await client.updateToken(5)
            const response = await axios.post(`${protocol}//${routeIP}:8000/utils/fetchJob`, {
                requestedJob: JobStatusState.requestedJob,
                workCenterListToggle: JobStatusState.workCenterListToggle,
                commentsToggle: JobStatusState.commentsToggle,
                componentJobsToggle: JobStatusState.componentJobsToggle,
                token: client.token
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-store'
                }
            })

            setJobStatusState(prev => ({...prev, response: response.data}))
            setJobStatusState(prev => ({...prev, linearProgressToggle: false}))
        } catch (error) {
            alert(error)
            setJobStatusState(prev => ({...prev, linearProgressToggle: false}))
        }
    }
    
    const handleSubmit = async () => {
        await fetchReport()
    }

    const handleChange = async (event) => {
        const name = event.target.name
        const value = event.target.value
        await setJobStatusState(prev => ({...prev, [name]: value}))
    }
    const handleReset = () => {
        setJobStatusState(initialState)
    }

    return (
        <Grid container sx={{ height: '100%', display: 'flex', gridTemplateRows: 'auto 1fr' }}>
            <Grid item sm={12} sx={{ height: '30%', minHeight: '195.81px' }}>
                <Grid container sx={{ height: '100%' }}>
                    <Grid item sm={10} sx={{ height: '100%' }}>
                        <Paper elevation={1} sx={{ height: '100%' }}>
                            { JobStatusState.linearProgressToggle === true ? (
                                <Box sx={{ width: '100%' }}>
                                    <LinearProgress />
                                </Box>
                                ) : (<Box sx={{ width: '100%', minHeight: '4px', backgroundColor: 'white' }}></Box>
                            )}
                            <Grid container sx={{ display: 'flex', justifyContent:'space-evenly', height: '100%' }}>
                                <Grid item sm={5}>
                                    <Box sx={{ margin: 1 }}>
                                        <Paper elevation={4} sx={{ backgroundColor: '#0e4780' }}>
                                            <Typography gutterBottom variant="h6" sx={{ textAlign: 'center', color:'white' }}>Input Job Number</Typography>
                                        </Paper>
                                        <Grid container>
                                            <Grid item sm={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                <FormControl sx={{ width: '80%', marginTop:.5}}>
                                                    <TextField
                                                        required
                                                        autoComplete='off'
                                                        id="selectedJob"
                                                        label="Job Number"
                                                        name="requestedJob"
                                                        value={JobStatusState.requestedJob}
                                                        onChange={handleChange}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item sm={5}>
                                    <Box sx={{ margin: 1 }}>
                                        <Paper elevation={4} sx={{ backgroundColor: '#0e4780' }}>
                                            <Typography gutterBottom variant="h6" sx={{ textAlign: 'center', color:'white' }}>Include</Typography>
                                        </Paper>
                                        <Grid container sx={{ marginTop: 2, display: 'flex' }}>
                                            <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                <FormControlLabel
                                                    sx={{ margin: 0 }}
                                                    control={
                                                        <Android12Switch 
                                                            checked={JobStatusState.workCenterListToggle}
                                                            onChange={(e) => {
                                                                return setJobStatusState(prev => ({...prev, workCenterListToggle: !JobStatusState.workCenterListToggle}))
                                                            }}
                                                        />
                                                    }
                                                    label="Work Centers (LOB)"
                                                />
                                            </Grid>
                                            <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                <FormControlLabel
                                                    sx={{ margin: 0 }}
                                                    control={
                                                        <Android12Switch 
                                                            checked={JobStatusState.commentsToggle}
                                                            onChange={(e) => {
                                                                return setJobStatusState(prev => ({...prev, commentsToggle: !JobStatusState.commentsToggle}))
                                                            }}
                                                        />
                                                    }
                                                    label="Comments"
                                                />
                                            </Grid>
                                            <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                <FormControlLabel
                                                    sx={{ margin: 0 }}
                                                    control={
                                                        <Android12Switch 
                                                            checked={JobStatusState.componentJobsToggle}
                                                            onChange={(e) => {
                                                                return setJobStatusState(prev => ({...prev, componentJobsToggle: !JobStatusState.componentJobsToggle}))
                                                            }}
                                                        />
                                                    }
                                                    label="Component Jobs"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item sm={12}>
                                    <Grid container sx={{ justifyContent: 'flex-end', height: '100%' }}>
                                        <Grid item sx={{ paddingBottom: 1.5, alignContent: 'end' }}>
                                            <Button
                                                variant="contained" 
                                                style={{ textTransform: 'none' }}
                                                id="submitButton"
                                                size='large'
                                                sx={{
                                                    backgroundColor: '#0e4780', 
                                                    width: 120, 
                                                    color: 'white', 
                                                    "&:hover": { backgroundColor: '#0B3866', color: "white" }
                                                }}
                                                onClick={() => {
                                                    handleSubmit()
                                                    const button = document.querySelector('#submitButton')
                                                    button.disabled = true
                                                    setTimeout(() => button.disabled = false, 1000)
                                                }}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                        <Grid item sx={{ paddingBottom: .5, margin: 1, alignContent: 'end' }}>
                                            <Button
                                                size='large'
                                                variant="contained"
                                                style={{ textTransform: 'none' }}
                                                id="resetButton"
                                                sx={{ 
                                                    backgroundColor: '#5d6c7c', 
                                                    width: 120, 
                                                    color: 'white', 
                                                    "&:hover": { backgroundColor: '#4f5d6a', color: "white" }
                                                }}
                                                
                                                onClick={() => {
                                                    handleReset()
                                                    const button = document.querySelector('#resetButton')
                                                    button.disabled = true
                                                    setTimeout(() => button.disabled = false, 1000)
                                                }}
                                            >
                                                Reset
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item sm={2} sx={{ height: '100%' }}>
                        <WcLegend/>
                    </Grid>
                </Grid>
            </Grid>
            { JobStatusState.response != null ? (
                <Grid item sm={12} sx={{ marginTop: 1, backgroundColor: 'lightgrey', height: '69%' }}>
                    <StatusTable State={JobStatusState} />
                </Grid>
            ):(<></>)}
        </Grid>
    )
}