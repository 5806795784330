import { Grid, Typography, Grow } from '@mui/material/';
import { useState, useEffect } from 'react';

const Banner = () => {
    const [checked, setChecked] = useState(false);
    useEffect(() => {
        setChecked(true)
    }, [])

    return (
        <Grid container 
            sx={{
                maxWidth: '1920px',
                display: 'flex',
                alignItems: 'center',
                marginInline: 'auto',
                backgroundColor: '#002233',
                paddingTop: { xs: 0, sm: 2, md: 8},
                paddingBottom: { xs: 0, sm: 2, md: 8}
            }}
        >
            <Grid item sm={0} md={0} lg={1}/>
            <Grid item sm={12} md={6} lg={5} sx={{ padding: { xs: 2 } }}>
                <Grow in={checked} timeout={2500}>
                    <Typography
                        sx={{  
                            color: 'white',
                            fontFamily: 'monospace',
                            typography: { xs: 'h3', sm: 'h3', md: 'h2', lg: 'h1' }
                        }}
                    >
                        Less friction,
                    </Typography>
                </Grow>

                <Grow in={checked} timeout={3500}>
                    <Typography 
                        sx={{
                            color: 'white',
                            fontFamily: 'monospace', 
                            paddingBottom: 2,
                            typography: { xs: 'h4', sm: 'h3', md: 'h3', lg: 'h2' }
                        }}
                    >
                        More manufacturing
                    </Typography>
                </Grow>

                <Grow in={checked} timeout={4500}>
                    <Typography 
                        variant="subtitle1" 
                        sx={{
                            color: 'white',
                            fontSize: { xs: 16, sm: 16, md: 16, lg: 20 }
                        }}
                    >
                        Apollo Software Solutions provides much-needed insight into the manufacturing experience.
                        From machine monitoring and report generation to a shop portal that lets customers see the status of their orders, Apollo is your gateway to a more transparent manufacturing environment
                    </Typography>
                </Grow>
            </Grid>

            <Grid item sm={12} md={6} lg={5} className='apolloLogoBackground' />
            <Grid item sm={0} md={0} lg={1}/>
        </Grid>
    );
}

export default Banner;
