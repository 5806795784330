import * as React from "react"
import { Grid, Typography, Button, IconButton,  } from "@mui/material/"
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import logo from "../Navbar/Logo.png"


export default function Footer( {client} ) {
    return (
        <Grid container alignItems="center" style={{ height: 'fit-content' }} sx={{ backgroundColor: '#0e4780', padding: 3, paddingBottom: 5 }}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <img src={ logo } alt="" />
            </Grid>
            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', color: 'white'}}>
                <Button variant="text" disableRipple={true} color="inherit" style={{ textTransform: 'none' }}>About</Button>
                <Button variant="text" disableRipple={true} color="inherit" style={{ textTransform: 'none', '&:hover': { cursor: 'pointer' } }} onClick={() => { window.location.replace('/bugReport') }}>Bug Report</Button>
            </Grid>
            <Grid item xs={12}sx={{ display: 'flex', justifyContent: 'center', color:"white", paddingTop: 3 }}>
                <div>
                    { client.realmAccess['roles'].includes("VisitorSignIn") ? 
                        (
                            <div>
                                <InstagramIcon sx={{ '&:hover': { cursor: 'pointer', opacity: 0.8 } }} />
                                <LinkedInIcon sx={{ '&:hover': { cursor: 'pointer', opacity: 0.8 } }} />
                            </div>
                        ) : 
                            <div>
                                <InstagramIcon sx={{ '&:hover': { cursor: 'pointer', opacity: 0.8 } }} onClick={() => { window.open('https://www.instagram.com/randsmachining/') }} />
                                <LinkedInIcon sx={{ '&:hover': { cursor: 'pointer', opacity: 0.8 } }} onClick={() => { window.open('https://www.linkedin.com/') }} />
                            </div>
                    }
                </div>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', color:"white", paddingTop: 3 }}>
                <Typography variant="subtitle2" sx={{ paddingLeft: 1.3 }}>@2023 R&S Machining. All rights reserved</Typography>
                <IconButton
                    size="small"
                    sx={{ '&:hover': { cursor: 'default', opacity: 0.8 } }}
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => {client.logout({redirectUri: window.location.href})}}
                    color="inherit"
                />
            </Grid>
        </Grid>
    )
}